import { Button, InputNumber, message, Tag, Typography } from 'antd';
import type { BaseData } from 'egenie-common';
import { egeniePcTheme, formatNumber, printHelper, getStaticResourceUrl } from 'egenie-common';
import { ImgFormatter, MainSubStructureModel, request } from 'egenie-utils';
import _ from 'lodash';
import { action } from 'mobx';
import { Observer } from 'mobx-react';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ENUM_PLUS_SERVICE_TYPE } from '../../../utils';
import styles from './index.less';
import type { Store } from './model';
import type { AfterSaleDetailItem } from './types';
import { ENUM_RECEIVE_SEND_TYPE } from './types';

export class ContentModel {
  constructor(parent: Store) {
    this.parent = parent;
    this.gridModel.onQuery();
  }

  private isPrint = false;

  public handleBatchPrintUnicode = async(data: AfterSaleDetailItem[], uniqueCodeCover = true) => {
    const newData = data.filter((item) => item._num > 0);
    await Promise.all(newData.map(async(rowData) => {
      const info = await request<BaseData<{ tempData?: any; userDataList?: any[]; }>>({
        url: '/api/cloud/wms/rest/after/sale/print/unique/code/by/after/sale',
        method: 'POST',
        data: {
          tenantIdForQuality: rowData?.tenantId,
          num: rowData._num,
          skuId: rowData.skuId,
          ownerId: rowData.ownerId || this.parent.conditionModel.normalProgramme.filterItems.params.ownerId,
          tempType: '27',
          tempId: 0,
        },
      });
      const tempData = info?.data?.tempData;
      const userDataList = info?.data?.userDataList;

      if (!_.isEmpty(tempData) && Array.isArray(userDataList) && userDataList.length) {
        await printHelper.lodopPrintPlugin.print({
          preview: false,
          templateData: tempData,
          contents: userDataList,
          generateQrcodeImageParams: {
            match: /authStyle.*uniqueCode/i,
            logoSrc: getStaticResourceUrl('pc/ts/egenie-cloud-wms/others/printAuthStyleLogo.png'),
            logoWidth: 0.2,
            logoHeight: 0.2,
          },
        });

        const uniqueCode = userDataList.map((item) => item?.uniqueCode?.unique_code || item?.authStyle?.uniqueCode)
          .filter(Boolean)
          .join(',');
        this.gridModel.gridModel.rows.forEach((val) => {
          if (val._id === rowData._id) {
            if (uniqueCodeCover) {
              val._uniqueCode = uniqueCode;
            } else {
              val._uniqueCode = [
                val._uniqueCode,
                uniqueCode,
              ].filter(Boolean)
                .join(',');
            }
          }
        });
      }
    }));
  };

  @action private handlePrintUnicode = async(row: AfterSaleDetailItem) => {
    if (this.isPrint) {
      return;
    }

    this.isPrint = true;
    try {
      await this.handleBatchPrintUnicode([row]);
    } finally {
      this.isPrint = false;
    }
  };

  public gridModel = new MainSubStructureModel({
    buttons: [
      {
        permissionId: '2691_700',
        text: '添加退回商品',
        icon: 'icon-add',
        handleClick: () => {
          this.parent.handleShowReturnGoodsModal(true);
        },
      },
      {
        permissionId: '2691_701',
        text: '删除商品',
        icon: 'icon-delete',
        handleClick: () => {
          this.parent.handleDeleteGoods();
        },
      },
      {
        permissionId: '2691_107',
        text: '参数设置',
        icon: 'icon-setup',
        handleClick: () => {
          this.parent.handleShowParamModal(true);
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          width: 100,
          frozen: true,
          formatter: ({ row }) => {
            return (
              <div
                style={{
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  height: '100%',
                  display: 'flex',
                }}
              >
                {
                  row.receiveSendType == ENUM_RECEIVE_SEND_TYPE.send.value ? null : (
                    <Button
                      onClick={() => this.handlePrintUnicode(row)}
                      size="small"
                      type="link"
                    >
                      打印唯一码
                    </Button>
                  )
                }
                <Button
                  onClick={() => this.parent.handleShowExchangeGoodsModal(true, row)}
                  size="small"
                  type="link"
                >
                  换商品
                </Button>
              </div>
            );
          },
        },
        {
          key: 'picUrl',
          name: '图片',
          width: 64,
          formatter: ({ row }) => <ImgFormatter value={row.picUrl}/>,
        },
        {
          key: 'arrivalStatus',
          name: '退货状态',
          width: 72,
          formatter: ({ row }) => (
            <Observer>
              {() => (
                <span>
                  {this.parent.conditionModel.normalProgramme.filterItems.dict.arrivalStatus?.find((item) => item.value == row.arrivalStatus)?.label}
                </span>
              )}
            </Observer>
          ),
        },
        {
          key: 'receiveSendType',
          name: '换货类型',
          width: 72,
          formatter: ({ row }) => (
            <span>
              {Object.values(ENUM_RECEIVE_SEND_TYPE)
                .find((item) => item.value == row.receiveSendType)?.label}
            </span>
          ),
        },
        {
          key: 'addedValueService',
          name: '增值服务',
          width: 250,
          formatter: ({ row }) => {
            return (
              <Observer>
                {
                  () => (row.addedValueService || []).map((val) => (
                    <Tag
                      color="blue"
                      key={val}
                    >
                      {Object.values(ENUM_PLUS_SERVICE_TYPE)
                        .find((labelData) => labelData.value === _.toString(val))?.label}
                    </Tag>
                  ))
                }
              </Observer>
            );
          },
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 250,
          formatter: ({ row }) => (
            <CopyToClipboard
              onCopy={() => message.success({
                content: '复制成功',
                key: row.skuId,
              })}
              text={row.skuNo || ''}
            >
              <span>
                {row.skuNo}
                &nbsp;
                <i className="icon-copy"/>
              </span>
            </CopyToClipboard>
          ),
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
          formatter: ({ row }) => (
            <span
              style={{
                fontSize: egeniePcTheme.font['egenie-font-size-md'],
                fontWeight: 500,
              }}
              title={row.color}
            >
              {row.color}
            </span>
          ),
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
          formatter: ({ row }) => (
            <span
              style={{
                fontSize: egeniePcTheme.font['egenie-font-size-md'],
                fontWeight: 500,
              }}
              title={row.size}
            >
              {row.size}
            </span>
          ),
        },
        {
          key: '已退/申请',
          name: '已退/申请',
          width: 128,
          formatter: ({ row }) => (
            <span className={styles.returnNum}>
              {formatNumber(row.actualBackNum)}
              /
              {formatNumber(row.applyBackNum)}
            </span>
          ),
        },
        {
          key: '本次数量',
          name: '本次数量',
          width: 128,
          formatter: ({
            row,
            rowIdx,
          }) => {
            return (
              <Observer>
                {() => (
                  <InputNumber
                    className={styles.inputNumber}
                    disabled={row.receiveSendType == ENUM_RECEIVE_SEND_TYPE.send.value}
                    max={99999}
                    min={0}
                    onChange={(value) => this.gridModel.gridModel.rows[rowIdx]._num = formatNumber(value)}
                    size="large"
                    style={{ width: 96 }}
                    value={formatNumber(this.gridModel.gridModel.rows[rowIdx]._num)}
                  />
                )}
              </Observer>
            );
          },
        },
        {
          key: '问题类型/平台单号/平台状态',
          name: '问题类型/平台单号/平台状态',
          width: 250,
          formatter: ({ row }) => (
            <div className={styles.reason}>
              <Typography.Text
                ellipsis
                title={row.problemType}
              >
                {row.problemType}
              </Typography.Text>
              <Typography.Text
                ellipsis
                title={row.platformCode}
              >
                {row.platformCode}
              </Typography.Text>
              <Typography.Text
                ellipsis
                title={this.parent.conditionModel.normalProgramme.filterItems.dict.platformStatus?.find((item) => item.value == row.platformStatus)?.label}
              >
                <Observer>
                  {() => (
                    <span>
                      {this.parent.conditionModel.normalProgramme.filterItems.dict.platformStatus?.find((item) => item.value == row.platformStatus)?.label}
                    </span>
                  )}
                </Observer>
              </Typography.Text>
            </div>
          ),
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: '_id',
      sortByLocal: true,
      showCheckBox: true,
      showEmpty: false,
      pageSize: 50,
      rowHeight: 72,
      showRefresh: false,
      showPager: false,
    },

    // 主表查询api
    api: { onQuery: () => Promise.reject() },
    hiddenSubTable: true,
  });

  private parent: Store;
}
