export interface AfterSaleDetail {
  voiceType?: number;
  backCourierNo?: string;
  shopId?: string;
  wmsOrderId?: string;
  wmsOrderNo?: string;
  omsSaleId?: string;
  platformType?: string | number;
  wmsAfterSaleOrderId?: number;
  wmsAfterSaleOrderNo?: string;
  arrivalStatus?: number;
  oldServerRemark?: string;
  buyerNick?: string;
  backCourierId?: number;
  backCourierName?: string;
  ownerId?: number | string;
  platformStatus?: number;
  applyTime?: string;
  unpackager?: number;
  unpackageTime?: string;
  afterSaleType?: number;
  afterSaleRemark?: string;
  afterSaleGuide?: string;
  createTime?: string;
  updateTime?: string;
  tenantId?: number;
  creator?: number;
  operator?: number;
  oldCourierId?: number;
  oldCourierName?: string;
  oldCourierNo?: string;
  platformCode?: string;
  sourceType?: number;
  isUsable?: number;
  omsAfterSaleNo?: string;
  receiver?: string;
  receiverMobile?: string;
  receiverPhone?: string;
  postcode?: string;
  address?: string;
  detailAddress?: string;
  problemType?: string;
  warehouseId?: string;
  proxyOrder?: boolean;
  detailList?: AfterSaleDetailItem[];
}

export interface AfterSaleDetailItem {
  addedValueService?: string[];
  backCourierNo?: string;
  receiveSendType?: number;
  wmsAfterSaleOrderDetailId?: number;
  wmsAfterSaleOrderId?: number;
  _changeSku?: boolean;
  _num?: number;
  _id?: number | string;
  status?: string;
  productId?: number;
  productNo?: number;
  arrivalStatus?: string;
  skuId?: number;
  skuNo?: string;
  uniqueCode?: string;
  _uniqueCode?: string;
  barCode?: string;
  picUrl?: string;
  applyBackNum?: number;
  actualBackNum?: number;
  color?: string;
  size?: string;
  createTime?: string;
  updateTime?: string;
  tenantId?: number;
  creator?: string;
  operator?: string;
  isUsable?: number;
  platformCode?: string;
  platformStatus?: number;
  problemType?: number;
  ownerId?: number | string;
}

export enum enumOrderState {
  confirmArrival,
  empty,
  afterSale
}

export const ENUM_RECEIVE_SEND_TYPE = {
  refund: {
    value: 1,
    label: '退货',
  },
  send: {
    value: 2,
    label: '发出',
  },
};

export const otherInfoData = {
  receiverMobile: {
    value: 'receiverMobile',
    label: '手机号',
  },
  receiverPhone: {
    value: 'receiverPhone',
    label: '电话',
  },
  buyerNick: {
    value: 'buyerNick',
    label: '买家昵称',
  },
  platformCode: {
    value: 'platformCode',
    label: '平台单号',
  },
};

export enum enumVoiceType {
  notMainWarehouse = 1,
  needQualityTesting = 2,
}
