import type { InputRef } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-utils';
import { NormalProgramme, request } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import type { Store } from './model';
import { otherInfoData } from './types';

export class ConditionModel {
  constructor(parent: Store) {
    this.parent = parent;
  }

  // 平台单、买家昵称。。。
  @observable public otherSelect = otherInfoData.receiverMobile.value;

  @action public handleOtherSelectChange = (otherSelect: string) => {
    this.otherSelect = otherSelect;
  };

  @observable public otherInput = '';

  @action public handleOtherInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.otherInput = event.target.value;
  };

  @action public handleOtherInputSearch = (otherInput: string) => {
    if (!otherInput) {
      message.error(`请输入${otherInfoData[this.otherSelect]?.label}`);
      return;
    }

    this.parent.handleSearchAfterSale({ [this.otherSelect]: otherInput }, '/api/cloud/wms/rest/after/sale/get/arrival/info');
  };

  // 快递单、唯一吗
  @observable public uniqueCodeOrCourier = '';

  public uniqueCodeOrCourierRef = React.createRef<InputRef>();

  @action public handleUniqueCodeOrCourierChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.uniqueCodeOrCourier = event.target.value;
  };

  @action public handleUniqueCodeOrCourierSearch = (uniqueCodeOrCourier: string) => {
    if (!uniqueCodeOrCourier) {
      const error = '请扫描快递单号/唯一码';
      message.error({
        key: error,
        content: error,
      });
      return;
    }

    const validateReg = /^[a-zA-Z\d-./]+$/;
    if (!validateReg.test(uniqueCodeOrCourier)) {
      this.uniqueCodeOrCourier = '';
      this.uniqueCodeOrCourierRef.current.focus();
      const error = '请输入数字或者字母或者-或者.或者/';
      message.error({
        key: error,
        content: error,
      });
      return;
    }

    this.parent.handleSearchAfterSale({ uniqueCodeOrCourier }, '/api/cloud/wms/rest/after/sale/get/order/by/unique/code/or/courier');
  };

  // 商品编码
  @observable public skuBarOrUniqueCode = '';

  public skuBarOrUniqueCodeRef = React.createRef<InputRef>();

  @action public handleSkuBarOrUniqueCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.skuBarOrUniqueCode = event.target.value;
  };

  private parent: Store;

  @action public getShopData = (ownerId: string | number): void => {
    request<BaseData<Array<{ erpShopId: string | number; shopName: string; }>>>({ url: `/api/cloud/baseinfo/rest/shop/all/${ownerId}` })
      .then((info) => {
        this.normalProgramme.filterItems.addDict({
          shopId: (info.data || []).map((item) => ({
            value: `${item.erpShopId}`,
            label: item.shopName,
          })),
        });
      });
  };

  public normalProgramme = new NormalProgramme({
    count: 1,
    showButton: false,
    showCollapse: false,
    filterItems: [
      {
        type: 'select',
        field: 'ownerId',
        label: '货主',
        onChangeCallback: (ownerId: string) => {
          this.parent.contentModel.gridModel.gridModel.resetAll();
          this.parent.contentModel.gridModel.gridModel.rows = [];

          // 更新店铺
          this.normalProgramme.filterItems.updateFilterItem([
            {
              type: 'select',
              field: 'shopId',
              value: undefined,
              data: [],
            },
          ]);
          if (ownerId) {
            this.getShopData(ownerId);
          }
        },
      },
      {
        type: 'select',
        field: 'shopId',
        label: '店铺',
      },
      {
        type: 'select',
        field: 'backCourierId',
        label: '快递公司',
      },
      {
        type: 'input',
        field: 'backCourierNo',
        label: '快递单号',
        required: true,
      },
      {
        type: 'select',
        field: 'warehouseId',
        label: '仓库',
      },
      {
        type: 'input',
        field: 'receiver',
        label: '收货人',
      },
      {
        type: 'input',
        field: 'receiverMobile',
        label: '收货人手机',
      },
      {
        type: 'input',
        field: 'receiverPhone',
        label: '收货人电话',
      },
      {
        type: 'input',
        field: 'buyerNick',
        label: '买家昵称',
      },
      {
        type: 'input',
        field: 'address',
        label: '收货地址',
      },
      {
        type: 'input',
        field: 'detailAddress',
        label: '详细地址',
      },
      {
        type: 'input',
        field: 'oldServerRemark',
        label: '原客服备注',
      },
      {
        type: 'input',
        field: 'afterSaleRemark',
        label: '售后备注',
      },
      {
        type: 'input',
        field: 'afterSaleGuide',
        label: '售后说明',
      },
    ],
  });
}
