import { Button, Input, Layout, Select } from 'antd';
import { MainSubStructure, NormalProgrammeComponent } from 'egenie-utils';
import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import { AddGoodsModal } from './addGoodsModal/addGoodsModal';
import styles from './index.less';
import { Store } from './model';
import { ParamModal } from './paramModal';
import { enumOrderState, otherInfoData } from './types';

export default function() {
  const store = React.useMemo(() => new Store(), []);

  return (
    <Provider store={store}>
      <Layout className={styles.container}>
        <Layout.Sider
          theme="light"
          width="448"
        >
          <div className={styles.conditionContainer}>
            <ConditionTop/>
            <ConditionBill/>
            <ConditionBottom/>
          </div>
        </Layout.Sider>
        <Layout.Content>
          <RightContent/>
        </Layout.Content>
      </Layout>
      <ModalWrapper/>
    </Provider>
  );
}

@inject('store')
@observer
class ModalWrapper extends React.Component<{ store?: Store; }> {
  render() {
    const {
      showReturnGoodsModal,
      showExchangeGoodsModal,
      handleShowReturnGoodsModal,
      handleShowExchangeGoodsModal,
      returnGoodsCallback,
      exchangeGoodsCallback,
      showParamModal,
      handleShowParamModal,
      conditionModel,
    } = this.props.store;

    const params = { ownerId: conditionModel.normalProgramme.filterItems.params.ownerId as string || undefined };
    return (
      <>
        {
          showReturnGoodsModal ? (
            <AddGoodsModal
              callback={returnGoodsCallback}
              modalProps={{
                onCancel: () => handleShowReturnGoodsModal(false),
                title: '添加退回商品',
                okText: '保存',
              }}
              params={params}
            />
          ) : null
        }
        {
          showExchangeGoodsModal ? (
            <AddGoodsModal
              callback={exchangeGoodsCallback}
              modalProps={{
                onCancel: () => handleShowExchangeGoodsModal(false, {}),
                title: '换商品',
                okText: '保存',
              }}
              params={params}
              showTmp={false}
            />
          ) : null
        }
        {
          showParamModal ? (
            <ParamModal onCancel={() => handleShowParamModal(false)}/>
          ) : null
        }
      </>
    );
  }
}

@inject('store')
@observer
class ConditionTop extends React.Component<{ store?: Store; }> {
  render() {
    const {
      conditionModel: {
        uniqueCodeOrCourierRef,
        uniqueCodeOrCourier,
        handleUniqueCodeOrCourierChange,
        handleUniqueCodeOrCourierSearch,
        skuBarOrUniqueCodeRef,
        skuBarOrUniqueCode,
        handleSkuBarOrUniqueCodeChange,
        otherInput,
        handleOtherInputChange,
        handleOtherInputSearch,
        otherSelect,
        handleOtherSelectChange,
      },
      orderState,
      handleSkuBarOrUniqueCodeSearch,
    } = this.props.store;
    return (
      <>
        <header className={styles.topHeader}>
          <span>
            确认退货
          </span>
        </header>
        <section className={styles.topContent}>
          <section>
            <header>
              请扫描快递单号/唯一码
            </header>
            <Input
              allowClear
              onChange={handleUniqueCodeOrCourierChange}
              onPressEnter={(event: any) => handleUniqueCodeOrCourierSearch(event.target.value)}
              placeholder="请扫描快递单号/唯一码"
              prefix={<span className={styles.prefix}/>}
              ref={uniqueCodeOrCourierRef}
              size="large"
              value={uniqueCodeOrCourier}
            />
          </section>
          <section className={styles.topNumber}>
            <Select
              bordered={false}
              onChange={handleOtherSelectChange}
              options={Object.values(otherInfoData)}
              size="large"
              value={otherSelect}
            />
            <Input
              allowClear
              bordered={false}
              onChange={handleOtherInputChange}
              onPressEnter={(event: any) => handleOtherInputSearch(event.target.value)}
              placeholder={`请输入${otherInfoData[otherSelect]?.label}`}
              size="large"
              value={otherInput}
            />
          </section>
          <section>
            <header>
              请扫描商品编码
            </header>
            <Input
              allowClear
              disabled={enumOrderState.confirmArrival !== orderState}
              onChange={handleSkuBarOrUniqueCodeChange}
              onPressEnter={(event: any) => handleSkuBarOrUniqueCodeSearch(event.target.value)}
              placeholder="请扫描商品唯一码/SKU编码/条形码"
              ref={skuBarOrUniqueCodeRef}
              size="large"
              value={skuBarOrUniqueCode}
            />
          </section>
        </section>
      </>
    );
  }
}

@inject('store')
@observer
class ConditionBill extends React.Component<{ store?: Store; }> {
  render() {
    const { conditionModel: { normalProgramme }} = this.props.store;
    return (
      <section className={styles.conditionItem}>
        <header>
          单据信息
        </header>
        <section>
          <NormalProgrammeComponent store={normalProgramme}/>
        </section>
      </section>
    );
  }
}

@inject('store')
@observer
class ConditionBottom extends React.Component<{ store?: Store; }> {
  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  private handleKeyDown = (event) => {
    if (event.ctrlKey && event.keyCode === 13) {
      this.props.store.handleSave();
    }
  };

  render() {
    const {
      isAdd,
      handleSave,
      orderState,
    } = this.props.store;
    const orderStateLabel = {
      [enumOrderState.confirmArrival]: '确认到货',
      [enumOrderState.empty]: '创建空白单',
      [enumOrderState.afterSale]: '创建售后单',
    };
    return (
      <footer className={styles.conditionBottom}>
        <Button
          block
          loading={isAdd}
          onClick={handleSave}
          size="large"
          type="primary"
        >
          {orderStateLabel[orderState]}
        </Button>
        <section>
          (ctrl+enter快速确认)
        </section>
      </footer>
    );
  }
}

@inject('store')
@observer
class RightContent extends React.Component<{ store?: Store; }> {
  render() {
    const { store } = this.props;
    return (
      <div className={styles.rightContent}>
        <header>
          <span>
            确认退货
          </span>
        </header>
        <section>
          <MainSubStructure store={store.contentModel.gridModel}/>
        </section>
      </div>
    );
  }
}
