import { Form, message, Modal, Radio, Select, Switch } from 'antd';
import type { BaseData, ValueAndLabelData } from 'egenie-utils';
import { request } from 'egenie-utils';
import React from 'react';
import { getWarehouse, getWarehouseArea, getWarehouseBin } from '../../../utils';

interface ParamData {
  'generatorOtherStockOrder': boolean;
  'warehouseId': string | number;
  'warehouseAreaId': string | number;
  'warehouseBinId': string | number;
  'approve': string | number;
}

interface ParamModalProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCancel: Function;
}

export function ParamModal({
  onCancel,
}: ParamModalProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState<{ loading: boolean; params: { generatorOtherStockOrder: boolean; }; }>({
    loading: true,
    params: null,
  });

  // 仓库
  const [
    warehouseData,
    setWarehouseData,
  ] = React.useState<ValueAndLabelData>([]);

  // 库区
  const [
    warehouseAreaData,
    setWarehouseAreaData,
  ] = React.useState<ValueAndLabelData>([]);

  // 库位
  const [
    warehouseBinData,
    setWarehouseBinData,
  ] = React.useState<ValueAndLabelData>([]);

  // 提交
  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      request({
        url: '/api/cloud/wms/rest/after/sale/save/param',
        method: 'post',
        data: paramData.params,
      })
        .then(() => {
          message.success('保存成功');
          onCancel();
        })
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [paramData]);

  // init
  React.useEffect(() => {
    request<BaseData<ParamData>>({
      url: '/api/cloud/wms/rest/after/sale/query/param',
      method: 'post',
    })
      .then((info) => {
        form.setFieldsValue({
          generatorOtherStockOrder: Boolean(info.data?.generatorOtherStockOrder),
          approve: info.data?.approve ? `${info.data.approve}` : undefined,
          warehouseId: info.data?.warehouseId ? `${info.data.warehouseId}` : undefined,
          warehouseAreaId: info.data?.warehouseAreaId ? `${info.data.warehouseAreaId}` : undefined,
          warehouseBinId: info.data?.warehouseBinId ? `${info.data.warehouseBinId}` : undefined,
        });

        getWarehouse()
          .then(setWarehouseData);

        getWarehouseArea({ warehouseId: info.data?.warehouseId ? `${info.data.warehouseId}` : undefined })
          .then(setWarehouseAreaData);

        getWarehouseBin({
          warehouseId: info.data?.warehouseId ? `${info.data.warehouseId}` : undefined,
          warehouseAreaId: info.data?.warehouseAreaId ? `${info.data.warehouseAreaId}` : undefined,
        })
          .then(setWarehouseBinData);
      })
      .finally(() => {
        setParamData({
          loading: false,
          params: null,
        });
      });
  }, []);

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      okText="保存"
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title="参数设置"
      visible
      width={800}
    >
      <Form
        form={form}
        initialValues={{ generatorOtherStockOrder: false }}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="生成退货入库单"
          name="generatorOtherStockOrder"
          valuePropName="checked"
        >
          <Switch/>
        </Form.Item>
        <Form.Item
          label="退回仓库"
          name="warehouseId"
          rules={[
            {
              required: true,
              message: '请选择退回仓库',
            },
          ]}
        >
          <Select
            onChange={(value: string) => {
              form.setFieldsValue({
                warehouseAreaId: undefined,
                warehouseBinId: undefined,
              });
              setWarehouseAreaData([]);
              setWarehouseBinData([]);

              if (value) {
                getWarehouseArea({ warehouseId: value })
                  .then(setWarehouseAreaData);
              }
            }}
            options={warehouseData}
            placeholder="请选择退回仓库"
          />
        </Form.Item>
        <Form.Item
          label="库区"
          name="warehouseAreaId"
          rules={[
            {
              required: true,
              message: '请选择库区',
            },
          ]}
        >
          <Select
            onChange={(warehouseAreaId: string) => {
              form.setFieldsValue({ warehouseBinId: undefined });
              setWarehouseBinData([]);

              if (warehouseAreaId) {
                getWarehouseBin({
                  warehouseAreaId,
                  warehouseId: form.getFieldValue('warehouse'),
                })
                  .then(setWarehouseBinData);
              }
            }}
            options={warehouseAreaData}
            placeholder="请选择库区"
          />
        </Form.Item>
        <Form.Item
          label="库位"
          name="warehouseBinId"
          rules={[
            {
              required: true,
              message: '请选择库位',
            },
          ]}
        >
          <Select
            options={warehouseBinData}
            placeholder="请选择库位"
          />
        </Form.Item>
        <Form.Item
          label="审核状态"
          name="approve"
          rules={[
            {
              required: true,
              message: '请选择审核状态',
            },
          ]}
        >
          <Radio.Group>
            <Radio value="1">
              未审核
            </Radio>
            <Radio value="2">
              已审核
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}
