import { message } from 'antd';
import { formatNumber } from 'egenie-common';
import type { BaseData, FilterConfigData } from 'egenie-utils';
import { formatFilterConfigData, hasPermission, request } from 'egenie-utils';
import _ from 'lodash';
import { action, observable, set, toJS } from 'mobx';
import { getAndPlayVoice, getOwner, playVoice, voiceData } from '../../../utils';
import type { GoodsItem } from './addGoodsModal/addGoodsModal';
import { ConditionModel } from './conditionModel';
import { ContentModel } from './contentModel';
import type { AfterSaleDetail, AfterSaleDetailItem } from './types';
import { enumOrderState, enumVoiceType } from './types';

let i = 0;

export class Store {
  constructor() {
    this.conditionModel = new ConditionModel(this);
    this.contentModel = new ContentModel(this);
    request<FilterConfigData>({
      url: '/api/cloud/baseinfo/rest/filterSet2/getConfig2',
      params: {
        itemList: 'warehouse,courier',
        dictList: 'arrival_status,platform_status',
        module: '',
      },
    })
      .then((info) => {
        const list = formatFilterConfigData(info, {
          courier: 'backCourierId',
          warehouse: 'warehouseId',
          arrival_status: 'arrivalStatus',
          platform_status: 'platformStatus',
        });
        this.conditionModel.normalProgramme.filterItems.addDict(list.reduce((prev, current) => ({
          ...prev,
          [current.field]: current.data,
        }), {}));
      });

    // 货主
    getOwner()
      .then((data) => this.conditionModel.normalProgramme.filterItems.addDict({ ownerId: data }));
  }

  @action private clearState = () => {
    this.conditionModel.uniqueCodeOrCourier = '';
    this.conditionModel.otherInput = '';
    this.conditionModel.skuBarOrUniqueCode = '';
    this.conditionModel.normalProgramme.filterItems.reset();
    this.contentModel.gridModel.gridModel.resetAll();
    this.contentModel.gridModel.gridModel.rows = [];
    this.activeAfterSaleDetailItem = {};
    this.afterSaleDetail = {};
    this.orderState = enumOrderState.confirmArrival;
  };

  @action public handleDeleteGoods = () => {
    const deleteItems = (this.contentModel.gridModel.gridModel.selectRows || []).filter((item: AfterSaleDetailItem) => !item.wmsAfterSaleOrderDetailId)
      .map((item) => item._id);
    if (deleteItems.length) {
      this.contentModel.gridModel.gridModel.rows = toJS(this.contentModel.gridModel.gridModel.rows.filter((item: AfterSaleDetailItem) => !deleteItems.includes(item._id)));
    }
  };

  private findExistIndex = (skuId: number): number => {
    const rows: AfterSaleDetailItem[] = this.contentModel.gridModel.gridModel.rows;
    const findRows: AfterSaleDetailItem[] = rows.filter((item) => Boolean(item.wmsAfterSaleOrderId))
      .concat(rows.filter((item) => !item.wmsAfterSaleOrderId))
      .filter((item) => item.skuId === skuId);

    const existItem = findRows.find((item, index, array) => {
      if (item.wmsAfterSaleOrderId) {
        if (formatNumber(item.actualBackNum) + formatNumber(item._num) < formatNumber(item.applyBackNum)) {
          return true;
        } else {
          return index === array.length - 1;
        }
      } else {
        return true;
      }
    });

    if (existItem) {
      return this.contentModel.gridModel.gridModel.rows.findIndex((item) => item._id === existItem._id);
    } else {
      return -1;
    }
  };

  // 主要防止重复扫描
  private scanSkuBarOrUniqueCode = new Set<string>();

  @action public handleSkuBarOrUniqueCodeSearch = async(skuBarOrUniqueCode: string) => {
    if (!skuBarOrUniqueCode) {
      message.error('请扫描商品编码');
      return;
    }

    if (this.scanSkuBarOrUniqueCode.has(skuBarOrUniqueCode)) {
      message.error('重复扫描');
      return;
    }
    this.scanSkuBarOrUniqueCode.add(skuBarOrUniqueCode);

    message.loading({
      content: '正在查找',
      key: 'skuBarOrUniqueCode',
    });

    this.conditionModel.skuBarOrUniqueCode = '';
    try {
      const info = await request<BaseData<{ skuId?: number; uniqueCode: string; voiceType: number; }>>({
        url: '/api/cloud/wms/rest/after/sale/get/sku/by/scan/code',
        data: { skuBarOrUniqueCode },
        method: 'POST',
      });

      if (info.data?.voiceType === enumVoiceType.notMainWarehouse) {
        playVoice(voiceData.非主仓);
      } else if (info.data?.voiceType === enumVoiceType.needQualityTesting) {
        getAndPlayVoice('SKU将进入后续质检流程拆包无需检测');
      }

      const index = this.findExistIndex(info.data.skuId);
      if (index !== -1) {
        this.contentModel.gridModel.gridModel.rows[index]._num = formatNumber(this.contentModel.gridModel.gridModel.rows[index]._num) + 1;

        // 扫描的时候只能打印一个,而不是对应的数量
        await this.contentModel.handleBatchPrintUnicode(
          [
            {
              ...this.contentModel.gridModel.gridModel.rows[index],
              _num: 1,
            },
          ],
          false
        );
      }
    } finally {
      message.destroy('skuBarOrUniqueCode');
    }
  };

  public afterSaleDetail: AfterSaleDetail = {};

  @action public handleSearchAfterSale = (data: {[key: string]: string; }, url: string) => {
    this.clearState();

    message.loading({
      content: '正在查找',
      key: 'handleSearchAfterSale',
    });

    request<BaseData<AfterSaleDetail>>({
      url,
      data,
      method: 'POST',
    })
      .then((info) => {
        // 通过数据确定是售后单类型
        if (_.isObject(info.data) && !_.isEmpty(info.data)) {
          if (info.data.wmsAfterSaleOrderId) {
            this.orderState = enumOrderState.confirmArrival;
          } else {
            this.orderState = enumOrderState.afterSale;
          }
        } else {
          this.orderState = enumOrderState.empty;
        }

        // 获取店铺数据
        if (info.data?.ownerId) {
          this.conditionModel.getShopData(info.data.ownerId);
        }

        // 货主、店铺逻辑处理---多个合并
        this.conditionModel.normalProgramme.filterItems.updateFilterItem([
          {
            field: 'ownerId',
            type: 'select',
            disabled: this.orderState === enumOrderState.confirmArrival || this.orderState === enumOrderState.afterSale,
            required: this.orderState === enumOrderState.empty,
          },
          {
            field: 'shopId',
            type: 'select',
            disabled: this.orderState === enumOrderState.confirmArrival || this.orderState === enumOrderState.afterSale,
          },
        ]);

        const confirmArrivalAndAfterSaleCommon = () => {
          this.afterSaleDetail = info.data;
          this.contentModel.gridModel.gridModel.rows = (info.data.detailList || []).map((item) => ({
            ...item,
            _changeSku: false,
            _num: 0,
            _id: i++,
          }));

          Object.entries(info.data)
            .map((item) => ({
              field: item[0],
              value: _.toString(item[1]),
            }))
            .filter((item) => item.value)
            .forEach((item) => {
              this.conditionModel.normalProgramme.filterItems.originData.forEach((val) => {
                if (val.field === item.field) {
                  val.formatValue.call(val, item.value);
                }
              });
            });
        };

        if (this.orderState === enumOrderState.confirmArrival) {
          confirmArrivalAndAfterSaleCommon();
          this.conditionModel.skuBarOrUniqueCodeRef.current?.focus();
        } else if (this.orderState === enumOrderState.afterSale) {
          confirmArrivalAndAfterSaleCommon();
        } else if (this.orderState === enumOrderState.empty) {
          // bug18828
          if (data.uniqueCodeOrCourier && !data.uniqueCodeOrCourier.includes('-')) {
            this.conditionModel.normalProgramme.filterItems.updateFilterItem([
              {
                field: 'backCourierNo',
                type: 'input',
                value: data.uniqueCodeOrCourier,
              },
            ]);
          }
        }

        if (info.data?.voiceType === enumVoiceType.needQualityTesting) {
          getAndPlayVoice('SKU将进入后续质检流程拆包无需检测');
        }
      })
      .finally(() => message.destroy('handleSearchAfterSale'));
  };

  @observable public isAdd = false;

  @action public handleSave = async() => {
    if (!this.contentModel.gridModel.gridModel.rows.length) {
      message.warn({
        content: '请填写数据',
        key: '确认退货保存',
      });
      return;
    }

    if (this.isAdd) {
      return;
    }

    await this.conditionModel.normalProgramme.filterItems.validator();
    await this.contentModel.handleBatchPrintUnicode(this.contentModel.gridModel.gridModel.rows.filter((item) => !item._uniqueCode));

    this.isAdd = true;
    try {
      switch (this.orderState) {
        case enumOrderState.confirmArrival:
          if (hasPermission('2691_79')) {
            await this.handleArrival();
          }
          break;
        case enumOrderState.empty:
          await this.handleAddEmpty();
          break;
        case enumOrderState.afterSale:
          await this.handleAddAfterSale();
          break;
        default:
          break;
      }
      this.scanSkuBarOrUniqueCode.clear();
    } finally {
      this.isAdd = false;
    }
  };

  @action public handleAddEmpty = async() => {
    const rows: AfterSaleDetailItem[] = this.contentModel.gridModel.gridModel.rows;
    const data = {
      ...this.conditionModel.normalProgramme.filterItems.params,
      detailList: rows.map((item) => ({
        ...item,
        addedValueService: item.addedValueService || [],
        uniqueCode: item._uniqueCode,
        changeSku: item._changeSku,
        applyBackNum: item._num,
      })),
      sourceType: 0,
      afterSaleType: 1,
    };

    const info = await request<BaseData<string>>({
      url: '/api/cloud/wms/rest/after/sale/create/empty/order',
      method: 'post',
      data,
    });
    this.conditionModel.uniqueCodeOrCourierRef.current?.focus();
    this.clearState();
    if (info.info) {
      message.info(`${info.info}`);
    }
    message.success('新建空白单成功');
  };

  @action public handleAddAfterSale = async() => {
    const rows: AfterSaleDetailItem[] = this.contentModel.gridModel.gridModel.rows;

    const data = {
      ...this.conditionModel.normalProgramme.filterItems.params,
      detailList: rows.map((item) => ({
        ...item,
        uniqueCode: item._uniqueCode,
        changeSku: item._changeSku,
        applyBackNum: item._num,
        afterSaleCount: item.applyBackNum,
        addedValueService: item.addedValueService || [],
      })),
      oldCourierId: this.afterSaleDetail.oldCourierId,
      oldCourierNo: this.afterSaleDetail.oldCourierNo,
      platformCode: this.afterSaleDetail.platformCode,
      wmsOrderId: this.afterSaleDetail.wmsOrderId,
      wmsOrderNo: this.afterSaleDetail.wmsOrderNo,
      platformType: this.afterSaleDetail.platformType,
      omsSaleId: this.afterSaleDetail.omsSaleId,
      sourceType: 1,
      afterSaleType: 1,
      proxyOrder: this.afterSaleDetail.proxyOrder,
      tenantId: this.afterSaleDetail.tenantId,
    };

    const info = await request<BaseData<string>>({
      url: '/api/cloud/wms/rest/after/sale/create/afterSale/order',
      method: 'post',
      data,
    });
    this.conditionModel.uniqueCodeOrCourierRef.current?.focus();
    this.clearState();
    if (info.info) {
      message.info(`${info.info}`);
    }
    message.success('新建售后单成功');
  };

  @action public handleArrival = async() => {
    const rows: AfterSaleDetailItem[] = this.contentModel.gridModel.gridModel.rows;
    const detailVoList = rows.map((item) => ({
      receiveSendType: item.receiveSendType,
      wmsAfterSaleOrderId: item.wmsAfterSaleOrderId,
      wmsAfterSaleDetailOrderId: item.wmsAfterSaleOrderDetailId,
      skuId: item.skuId,
      changeSku: item._changeSku,
      num: item._num,
      uniqueCode: item._uniqueCode,
      addedValueService: item.addedValueService || [],
    }));

    const info = await request<BaseData<string>>({
      url: '/api/cloud/wms/rest/after/sale/confirm/return',
      method: 'post',
      data: {
        detailVoList,
        backCourierNo: this.conditionModel.normalProgramme.filterItems.params.backCourierNo,
      },
    });

    this.clearState();
    this.conditionModel.uniqueCodeOrCourierRef.current?.focus();
    if (info.info) {
      message.info(`${info.info}`);
    }
    message.success('到货成功');
  };

  // 售后、空白状态
  @observable public orderState: enumOrderState = enumOrderState.confirmArrival;

  // 参数设置
  @observable public showParamModal = false;

  @action public handleShowParamModal = (showParamModal: boolean) => {
    this.showParamModal = showParamModal;
  };

  // 添加、换商品
  @observable public showReturnGoodsModal = false;

  @action public handleShowReturnGoodsModal = (showReturnGoodsModal: boolean) => {
    this.showReturnGoodsModal = showReturnGoodsModal;
  };

  @action public returnGoodsCallback = (rows: GoodsItem[]) => {
    if (!rows.length) {
      message.warn('请选择数据');
      return;
    }

    rows.forEach((goodsItem) => {
      const index = this.findExistIndex(goodsItem.id);
      if (index === -1) {
        this.contentModel.gridModel.gridModel.rows.push({
          _changeSku: false,
          _num: 1,
          _id: i++,
          addedValueService: goodsItem.addedValueService || [],
          wmsAfterSaleOrderId: this.afterSaleDetail.wmsAfterSaleOrderId,
          skuId: goodsItem.id,
          picUrl: goodsItem.pic,
          skuNo: goodsItem.skuNo,
          barCode: goodsItem.barCode,
          color: goodsItem.color,
          size: goodsItem.size,
          productId: goodsItem.productId,
          productNo: goodsItem.productNo,
        });
      } else {
        this.contentModel.gridModel.gridModel.rows[index]._num = formatNumber(this.contentModel.gridModel.gridModel.rows[index]._num) + 1;
      }
    });

    this.handleShowReturnGoodsModal(false);
  };

  @observable public showExchangeGoodsModal = false;

  private activeAfterSaleDetailItem: AfterSaleDetailItem = {};

  @action public handleShowExchangeGoodsModal = (showExchangeGoodsModal: boolean, activeAfterSaleDetailItem: AfterSaleDetailItem) => {
    this.showExchangeGoodsModal = showExchangeGoodsModal;
    this.activeAfterSaleDetailItem = activeAfterSaleDetailItem;
  };

  @action public exchangeGoodsCallback = (rows: GoodsItem[]) => {
    if (rows.length !== 1) {
      message.warn('请选择一条数据');
      return;
    }

    if (_.isEmpty(this.activeAfterSaleDetailItem)) {
      return;
    }

    const tableData: AfterSaleDetailItem[] = this.contentModel.gridModel.gridModel.rows;
    const oldPosition = tableData.findIndex((item) => item._id === this.activeAfterSaleDetailItem._id);
    if (oldPosition === -1) {
      return;
    }

    let existPosition: number;
    if (this.activeAfterSaleDetailItem.wmsAfterSaleOrderId) {
      existPosition = tableData.findIndex((item) => item.skuId === rows[0].id && item.wmsAfterSaleOrderId === this.activeAfterSaleDetailItem.wmsAfterSaleOrderId);
    } else {
      existPosition = tableData.findIndex((item) => item.skuId === rows[0].id && item.wmsAfterSaleOrderId == null);
    }

    if (existPosition === -1) {
      set(this.contentModel.gridModel.gridModel.rows[oldPosition], {
        _changeSku: true,
        skuId: rows[0].id,
        picUrl: rows[0].pic,
        skuNo: rows[0].skuNo,
        barCode: rows[0].barCode,
        color: rows[0].color,
        size: rows[0].size,
        productId: rows[0].productId,
        productNo: rows[0].productNo,
        addedValueService: rows[0].addedValueService,
      });
      this.handleShowExchangeGoodsModal(false, {});
    } else {
      message.warn('不能更换为已经存在的商品');
    }
  };

  public conditionModel: ConditionModel;

  public contentModel: ContentModel;
}

